import _ from 'lodash';
import { reactive, computed, ref } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';

export default function AffiliateUser(props, context) {
  const form = ref(null);
  const state = reactive({
    operation: Operation.NONE,
    items: [],
    total: 0,
    filters: {},
    options: {
      rowsPerPage: 100,
      page: 0,
    },
    valid: false,
    item: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
    passwordForgottenLoading: false,
    passwordForgottenError: false,
    passwordForgottenSuccess: false,
    uniqueEmailError: false,
  });

  const actions = {
    async applyFilter({ key, value }) {
      if (_.isEmpty(value)) {
        delete state['filters'][key];
      } else {
        _.set(state, `filters.${key}`, value);
      }
      await this.loadItems({});
    },
    async removeFilter({ key }) {
      delete state['filters'][key];
      await this.loadItems({});
    },
    async applyOptions({ value }) {
      _.set(state, 'options', value);
      await this.loadItems({});
    },
    async loadItems() {
      try {
        _.set(state, 'operation', Operation.IS_GETTING);
        let filters = _.get(state, 'filters');
        let options = _.get(state, 'options');
        const params = {
          ...filters,
          limit: options.rowsPerPage,
          page: options.page,
        };
        const result = await HttpClientV2.callFunctionV2('GET', 'affiliateuser', params);
        _.set(
          state,
          'items',
          _.sortBy(
            result.resultSet.filter((l) => !_.isEmpty(l.name)),
            ['name']
          )
        );
        _.set(state, 'total', result.total);
      } catch (error) {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
      } finally {
        _.set(state, 'operation', Operation.NONE);
      }
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
    },
    async unselectItem() {
      _.set(state, 'uniqueEmailError', false);
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
      await this.loadItems();
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async editItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if ([ItemState.PERSISTENT, ItemState.TRANSIENT, ItemState.DETACHED].includes(itemState)) {
        _.set(state, 'isEditing', true);
      } else {
        throw new Error('The item must be in the PERSISTENT, TRANSIENT or DETACHED state to edit.');
      }
    },
    async saveItem() {
      if (context.refs.form) {
        let validation = context.refs.form.validate();
        if (!validation) return;
      }
      if (state.itemState == ItemState.TRANSIENT) {
        _.set(state, 'uniqueEmailError', false);
        _.set(state, 'operation', Operation.IS_CREATING);
        const createCall = await HttpClientV2.callFunctionV2('POST', 'affiliateuser', state.item);
        if (createCall.status == '200') {
          await actions.unselectItem();
          _.set(state, 'operation', Operation.NONE);
          return await actions.loadItems();
        } else if (createCall.response?.data?.message?.startsWith('Email')) {
          {
            _.set(state, 'uniqueEmailError', true);
            _.set(state, 'operation', Operation.NONE);
          }
        }
      } else if (state.itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_UPDATING);
          delete state.item._affiliate;
          const updateCall = await HttpClientV2.callFunctionV2('PUT', 'affiliateuser', state.item);
          if (updateCall.status == '200') {
            await actions.unselectItem();
            return await actions.loadItems();
          } else {
            if (updateCall.response?.data?.message?.startsWith('Email')) {
              _.set(state, 'uniqueEmailError', true);
              _.set(state, 'operation', Operation.NONE);
            }
          }
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the TRANSIENT or DETACHED state to save.');
      }
    },
    async deleteItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      if (state.itemState == ItemState.PERSISTENT || state.itemState == ItemState.DETACHED) {
        _.set(state, 'itemState', ItemState.NOT_SELECTED);
        try {
          _.set(state, 'operation', Operation.IS_DELETING);
          await HttpClientV2.callFunctionV2('DELETE', 'affiliateuser', { _id: state.item._id });
          await this.unselectItem({});
          await this.loadItems({});
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the PERSISTENT or DETACHED state to delete.');
      }
    },
    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      if (key === 'email') {
        value = value ? value.trim() : value;
        _.set(state, 'uniqueEmailError', false);
      }
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      if (state.itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
    async passwordForgotten(email) {
      state.passwordForgottenLoading = true;
      state.passwordForgottenSuccess = false;
      state.passwordForgottenError = false;
      try {
        await HttpClientV2.callFunctionV2('PUT', 'affiliateuser/updatePassword', {
          _id: state.item._id,
          email: email,
        });
        state.passwordForgottenSuccess = true;
      } catch (error) {
        state.passwordForgottenError = true;
      }
      state.passwordForgottenLoading = false;
    },
  };
  return { form, state, actions };
}
